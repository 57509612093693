<template>
  <div class="fuiler" @click.stop="exit(1)" id="target">
    <div class="nav_block">
      <Header class="Header" rel="Header"></Header>
    </div>

    <div class="main">
      <div class="router">
        首页>>当季主题>>{{ Title }}>>正文<i @click.stop="openmenu"></i>
        <div class="menu" @click.stop="exit(0)" :class="menu">
          <div class="blocks" @click="share">分享</div>
          <div class="blocks" @click="Give">点赞</div>
          <div class="blocks" @click="black">返回</div>
        </div>
      </div>
      <div class="data">
        <div class="title">{{ data.Title }}</div>
        <div class="text" v-html="data.Content"></div>
      </div>

      <div class="comment">
        <div class="lisk">
          <input type="text" placeholder="请发表您的看法" v-model="from.Text" />
          <div class="send" @click="send">发送></div>
        </div>

        <div class="number">{{ CommentData.length }}条评论</div>
        <div class="coData">
          <div class="block" v-for="(item, index) in CommentData" :key="index">
            <div class="ico"></div>
            <div class="name">评论者：{{ item.UserInfo.Name }}</div>
            <div class="text">{{ item.Text }}</div>
            <div class="time">{{ item.NewTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <Share ref="Share"></Share>
    <Footer class="Footer"></Footer>
  </div>
</template>

<script>
import Share from "../conponents/Share";
import Footer from "../conponents/Footer";
import Header from "../conponents/Header";
import wx from "weixin-js-sdk";
import {
  findcurrent,
  giveSet,
  sendComment,
  findComment,
  Stoke,
} from "../../../api/browseArticles";
import Fingerprint2 from "fingerprintjs2";
import { Dialog, Notify } from "vant";

export default {
  components: {
    Share,
    Footer,
    Header,
    [Dialog.name]: Dialog,
    [Notify.Component.name]: Notify.Component,
  },
  data() {
    return {
      Title: "",
      Team: [],
      Articles_id: "",
      data: {},
      from: {
        Text: "",
      },
      CommentData: [],
      menu: "closemenu",
    };
  },
  created() {},
  mounted() {
    this.test();
    let _id = this.$route.params.id;
    this.id = _id;
    document.body.style.overflow = "auto";
    findcurrent({ _id }).then((res) => {
      let data = res.sum;
      let Title = data.Title;
      if (Title.length >= 6) {
        Title = Title.substring(0, 6) + "……";
      }
      this.Title = Title;

      this.Team = data.Team;

      this.Articles_id = data.Articles_id;
      document.getElementById("target").scrollIntoView();
      // let text = data.content.replace(/<p>/g, "<p style='text-indent: 1rem'>");
      // data.content = text;

      this.data = data;
    });
    this.frechComment();
  },
  methods: {
    test() {
      let url = window.location.href;

      Stoke({ url }).then((res) => {
        let signature = res.signature;
        let noncestr = res.noncestr;
        let timestamp = res.timestamp;

        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxa8ce8daa4ddb25f2", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "onMenuShareAppMessage"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: "测试", // 分享标题
            desc: "我是描述", // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://ideaer.oss-cn-hangzhou.aliyuncs.com/image/2021-7-9/3beecc3c69c273400317a5b6854c2899.jpg", // 分享图标
            success: function () {
              alert("已分享");
              // 设置成功
            },
          });
        });
      });
    },
    share() {
      this.$refs.Share.open(this.data.Title, this.Team, this.Articles_id);
    },
    openmenu() {
      this.menu = "openmenu";
    },
    frechComment() {
      let _id = this.$route.params.id;
      findComment({ _id }).then((res) => {
        this.CommentData = res.sum;
      });
    },
    send() {
      let UserInfo = this.$store.state.UserInfo;
      let Text = this.from.Text;
      let ArticleID = this.id;

      if (UserInfo.Name) {
        if (Text) {
          sendComment({ UserInfo, Text, ArticleID }).then((res) => {
            if (res.code == 0) {
              Notify({ type: "success", message: res.message });
              this.frechComment();
              this.$refs.Header.UserInfos();
            }
          });
          this.from.Text = "";
        } else {
          Dialog.alert({
            title: "系统",
            message: "你未输入评论内容",
          });
        }
      } else {
        Dialog.alert({
          title: "系统",
          message: "请你登陆账号后才能参与评论",
        });
      }
    },
    // 指纹获取
    Finge: async function () {
      return new Promise((resolve) => {
        Fingerprint2.get(function (components) {
          const values = components.map(function (component, index) {
            if (index === 0) {
              return component.value.replace(/\bNetType\/\w+\b/, "");
            }
            return component.value;
          });
          resolve(Fingerprint2.x64hash128(values.join(""), 31));
        });
      });
    },
    Give: async function () {
      let Murmur = await this.Finge();
      let _id = this.id;
      let UserInfo = this.$store.state.UserInfo;
      let addData = {};
      if (UserInfo.Name) {
        addData = { Murmur, _id, UserInfo };
      } else {
        addData = { Murmur, _id };
      }
      giveSet(addData).then((res) => {
        if (res.code == 0) {
          this.exit(1);
          Notify({ type: "success", message: res.message });
          let UserInfo = this.$store.state.UserInfo;
          if (UserInfo.PhoneMobile) {
            this.$refs.Header.UserInfos();
          }
        } else {
          Notify({ type: "warning", message: res.message });
        }
      });
    },
    exit(str) {
      if (str == 0) {
        return;
      }
      this.menu = "closemenu";
    },
    black() {
      let query = this.$route.query;
      let qs = query.qs;
      if (qs) {
        this.$router.go(-1);
      } else {
        this.$router.push("/mobile/home");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fuiler {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 100% 100%;

  .nav_block {
    position: relative;
    background-image: url("../../../assets/Image/public/bg.png");
    background-size: 100% 100%;
    width: 100%;
    height: 10rem;
    margin-bottom: 3rem;

    .Header {
      background-image: url("../../../assets/Image/public/header.png");
      background-size: 100% 100%;
      height: 3rem;
    }

    @mixin nav_block {
      position: absolute;
      width: 18rem;
      height: 7rem;
      padding: 0.8rem;
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 0 15px 1px #78432e;
      font-size: 0.8rem;
      line-height: 1rem;
      top: 4rem;
      text-indent: 1rem;
    }

    .block {
      left: 50%;
      margin-left: -10rem;
      @include nav_block;
    }

    .NavTitle {
      background-image: url("../../../assets/Image/public/NavTitle.png");
      background-size: 100% 100%;
      width: 7rem;
      height: 2rem;
      display: inline-block;
    }
  }

  .main {
    width: 100%;

    .black {
      width: 100%;
      height: 2rem;
      margin-top: 0.8rem;
      margin-bottom: 1.6rem;
      position: relative;

      .browse {
        position: absolute;
        left: 1.9rem;
        font-size: 0.8rem;
        top: 0.5rem;

        a {
          color: #78432e;
          text-decoration: underline;
        }
      }

      .share {
        position: absolute;
        right: 7.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }

      .fings {
        position: absolute;
        right: 14.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }

      .block {
        position: absolute;
        right: 1.5rem;
        border-radius: 0.4rem;
        font-size: 0.8rem;
        padding: 0.5rem 1.5rem;
        background-color: #93523a;
        color: #ffffff;
      }
    }

    .router {
      font-size: 0.5rem;
      color: #666;
      width: 100%;
      text-indent: 1rem;
      margin-bottom: 1.2rem;
      position: relative;

      i {
        position: absolute;
        width: 1.2rem;
        height: 1.2rem;
        right: 1rem;
        top: -0.2rem;
        background-image: url("../../../assets/Image/mobile/browseText/menu.png");
        background-size: 100% 100%;
      }

      .menu {
        position: absolute;
        width: 6rem;
        height: 6rem;
        background-color: #fff;
        border: 1px solid #ff725e;
        border-radius: 0.3rem;
        right: 1rem;
        top: 1.8rem;

        .blocks {
          width: 100%;
          height: 2rem;
          line-height: 2rem;
          text-align: center;
          text-indent: 0;
          color: #778398;
          border-bottom: 1px solid #ff725e;
        }

        .blocks:last-child {
          border: none;
        }
      }
    }

    .data {
      width: 22rem;
      margin: 0 auto;
      min-height: 30rem;
      margin-bottom: 1.5rem;

      .title {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.9rem;
      }

      .text {
        font-size: 0.9rem;
        line-height: 1.6rem;
        p {
          text-indent: 2rem;
        }
      }
    }

    .lisk {
      width: 100%;
      height: 1.4rem;
      margin-bottom: 1.5rem;
      position: relative;

      input {
        background-color: #f2f2f2;
        width: 17rem;
        height: 1.4rem;
        line-height: 1.4rem;
        border-radius: 0.5rem;
        font-size: 0.5rem;
        text-indent: 1rem;
        border: none;
      }

      .send {
        color: #ff725e;
        position: absolute;
        right: 1rem;
        top: 0.1rem;
        font-size: 0.7rem;
      }
    }

    .comment {
      width: 22rem;
      margin: 0 auto;

      .number {
        font-size: 0.5rem;
        color: #666;
        width: 100%;
        margin-bottom: 0.7rem;
      }

      .coData {
        margin-bottom: 2rem;

        .block {
          width: 20rem;
          min-height: 3rem;
          position: relative;
          margin: 0 auto;
          margin-bottom: 0.8rem;

          .ico {
            background-color: #93523a;
            border-radius: 10rem;
            width: 0.5rem;
            height: 0.5rem;
            display: inline-block;
            position: absolute;
            left: -0.8rem;
            top: 0.5rem;
          }

          .name {
            margin-left: 0.5rem;
            color: #666666;
            font-size: 0.5rem;
            margin-bottom: 0.4rem;
          }

          .text {
            margin-left: 0.8rem;
            font-size: 0.5rem;
          }

          .time {
            margin-left: 0.5rem;
            color: #666666;
            font-size: 0.5rem;
            margin-top: 0.4rem;
          }
        }
      }
    }
  }

  .Footer {
    background-image: url("../../../assets/Image/public/header.png");
    background-size: 100% 100%;
  }

  .openmenu {
    display: block;
  }
  .closemenu {
    display: none;
  }
}
</style>

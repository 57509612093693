<template>
  <div class="Share" v-if="Share">
    <!-- 海报html元素 -->
    <div id="posterHtml" v-if="posterHtml">
      <!--      <div class="bg"></div>-->
      <img
        src="../../../assets/Image/mobile/browseText/wrtsTwo.jpg"
        class="bg"
      />
      <!-- 二维码 -->
      <div class="ml30 mr30 mt30 mb30 p30">
        <div class="width-full block" id="qrcodeImg"></div>
      </div>
      <div class="Articles_id">{{ Articles_id }}</div>
      <!--      作者-->
      <div class="Name" v-if="Team">
        <span class="s" v-for="(item, index) in Team" :key="index">
          {{ item.Name }}
        </span>
      </div>
      <div class="Title">{{ Title }}</div>
    </div>
    <img id="createSum" v-if="createSum" :src="posterImg" />

    <!-- 点击生成海报并下载本地 -->
    <div class="down">
      <van-button
        color="#ff725e"
        @click="createPoster"
        class="block"
        v-if="!posterImg"
        >生成海报</van-button
      >
      <van-button color="#ff725e" class="block" v-if="posterImg"
        >请长按分享海报保存</van-button
      >
      <van-button color="#5f6e88" @click="close" class="block" v-if="!posterImg"
        >取消</van-button
      >
      <van-button color="#5f6e88" @click="close" class="block" v-if="posterImg"
        >关闭</van-button
      >
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { Button, Notify } from "vant";

export default {
  data() {
    return {
      createSum: false,
      posterHtml: false,
      posterImg: "",
      Share: false,
      Title: "",
      Team: [],
      Articles_id: "",
    };
  },
  components: {
    [Button.name]: Button,
    [Notify.Component.name]: Notify.Component,
  },
  mounted() {},
  methods: {
    close() {
      this.posterImg = "";
      this.Share = false;
      this.posterHtml = false;
      this.createSum = false;
      document.body.style.overflow = "auto";
      this.qrcode = null;
    },
    open(Title, Team, Articles_id) {
      this.Share = true;
      this.Articles_id = Articles_id;
      this.Title = "《" + Title + "》";
      this.Team = Team;
      document.body.style.overflow = "hidden";
      this.posterHtml = true;
      let puth = "browseText";
      let _id = this.$route.params.id;
      let host = window.location.host;
      let urlSum =
        "http://" + host + "/mobile/home?puth=" + puth + "&_id=" + _id;
      // let url = window.location.href;
      // url = url.replace("?qs=1", "");

      setTimeout(() => {
        this.createQrcode(urlSum);
      }, 100);
    },
    createQrcode(text) {
      // 生成二维码
      const qrcodeImgEl = document.getElementById("qrcodeImg");
      qrcodeImgEl.innerHTML = "";
      this.qrcode = new QRCode(qrcodeImgEl, {
        width: 150,
        height: 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.qrcode.makeCode(text);
    },
    createPoster() {
      const vm = this;
      const domObj = document.getElementById("posterHtml");
      html2canvas(domObj, {
        useCORS: true,
        allowTaint: false,
        logging: false,
        letterRendering: true,
      }).then((canvas) => {
        setTimeout(() => {
          // 在微信里,可长按保存或转发
          vm.posterImg = canvas.toDataURL("image/png");
          this.posterHtml = false;
          this.createSum = true;
          Notify({ type: "success", message: "生成成功" });
          // vm.imgDown(vm.posterImg);
        }, 500);
      });
    },
    // 保存图片到本地
    imgDown(img) {
      var alink = document.createElement("a");
      alink.href = img;
      alink.download = this.Title; // 图片名
      alink.click();
      Notify({ type: "success", message: "分享成功" });
      this.close();
    },
  },
};
</script>

<style scoped lang="scss">
.Share {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
#posterHtml {
  width: 270px;
  height: 430px;
  position: fixed;
  left: 50%;
  margin-left: -135px;
  top: 60px;
}
#createSum {
  width: 270px;
  height: 430px;
  position: fixed;
  left: 50%;
  margin-left: -135px;
  top: 60px;
}

#qrcodeImg {
  position: absolute;
  top: 150px;
  left: 50%;
  margin-left: -75px;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.Articles_id {
  position: absolute;
  color: #fff;
  top: 352px;
  width: 100%;
  text-align: center;
  font-size: 9px;
}
.Name {
  position: absolute;
  color: #fff;
  top: 380px;
  width: 100%;
  text-align: center;
  font-size: 11px;
  span {
    margin-right: 4px;
  }
}

.Title {
  position: absolute;
  color: #fff;
  top: 325px;
  width: 100%;
  text-align: center;
  font-size: 13px;
}

.down {
  position: fixed;
  top: 510px;
  width: 300px;
  left: 50%;
  margin-left: -150px;
  display: flex;
  justify-content: space-between;
}

.open {
  display: block;
}
.close {
  display: none;
}
</style>
